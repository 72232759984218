import { FC, FormEvent, useState, useCallback, useEffect } from "react";

import { AES, enc } from "crypto-js";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "@mui/x-date-pickers/locales";
import "dayjs/locale/pt";

import CheckIcon from "@mui/icons-material/CheckRounded";

import { baseAPI, baseHeaders } from "src/shared/config/axios";

import { useToast } from "src/shared/contexts/Toast";
import { useProduct } from "src/shared/contexts/Product";
import { usePayment } from "src/shared/contexts/Payment";

import IAccordion from "src/shared/interfaces/accordions";
import IPaymentRule, {
  IFee,
} from "src/shared/interfaces/accordions/paymentInfoAccordion";

import CustomLoader from "src/shared/components/general/Loader";
import CustomAccordion from "src/shared/components/general/Accordion";

import {
  InputList,
  InputItem,
} from "src/shared/styles/accordions/accordion.style";
import {
  StyledRadioGroup,
  StyledGenericFormControlLabel,
  StyledSpecificFormControlLabel,
} from "./style";
import { ProductTypesIds, ProductIds } from "src/shared/utils/enums";
import styled from "styled-components";

const BoletoVality = styled.p`
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const PaymentInfoAccordion: FC<IAccordion> = ({
  id,
  disabled,
  expanded,
  accordions,
  setAccordions,
  setActiveStep,
}) => {
  /**Função de adição de um toast informativo */
  const { addToast } = useToast();
  /**Variáveis que mantém o(s) objeto(s) do(s) produto(s) sendo adquirido(s) pelo usuário */
  const { acquiredProducts, productTypes } = useProduct();
  /**Variável que mantém as informações de pagamento do usuário */
  const { paymentInfo, setPaymentInfo } = usePayment();

  /**Variável contendo as regras de negócio de pagamento do sistema */
  const [paymentRules, setPaymentRules] = useState<IPaymentRule>(
    {} as IPaymentRule
  );

  /**Variável que mantém a forma de pagamento genérica atualmente selecionada (cartão, boleto) */
  const [
    currentGenericSelectedPaymentType,
    setCurrentGenericSelectedPaymentType,
  ] = useState(
    paymentInfo.payment_type === "boleto" ||
      paymentInfo.payment_type === "boleto_parcelado"
      ? "Boleto"
      : "Cartão"
  );
  /**Variável que mantém a forma de pagamento específica atualmente selecionada (parcelado, recorrente) */
  const [
    currentSpecificSelectedPaymentType,
    setCurrentSpecificSelectedPaymentType,
  ] = useState(
    paymentInfo.payment_type !== "-" ? paymentInfo.payment_type : "parcelado"
  );

  /**Array que mantém as parcelas disponíveis para pagamento, em que cada posição mantém o valor total a ser pago */
  const [installments, setInstallments] = useState<number[]>([]);
  /**Variável contendo a opção de parcelamento selecionada pelo usuário */
  const [currentSelectedInstallment, setCurrentSelectedInstallment] =
    useState<number>(
      () =>
        JSON.parse(
          new URL(window.location.href).searchParams.get(
            "igti_checkout_payment_info"
          )
            ? AES.decrypt(
                window.location.href.split("igti_checkout_payment_info=")[1],
                process.env.REACT_APP_CHECKOUT_URL_SECRET_KEY!
              ).toString(enc.Utf8)
            : '{"installments": 1}'
        ).installments ?? 1
    );

  /**Estado que mantém o link do boleto gerado para o usuário, caso esta tenha sido a opção de pagamento escolhida */
  const [ticket, setTicket] = useState("");

  /**Estado que mantém o nome do titular do cartão */
  const [name, setName] = useState("");

  /**Estado que mantém o número do cartão */
  const [number, setNumber] = useState("");
  /**Variável que mantém a bandeira do cartão */
  const [banner, setBanner] = useState("");

  /**Estado que mantém a data de validade do cartão */
  const [validity, setValidity] = useState("");

  /**Estado que mantém o cvv do cartão */
  const [cvv, setCvv] = useState("");

  /**Variável que mantém o valor total da compra sem descontos */
  const [totalValue, setTotalValue] = useState(0);
  /**Variável que mantém o valor total da(s) taxa(s) de matrícula (não participa(m) dos descontos) */
  const [enrollmentValue, setEnrollmentValue] = useState(0);
  /**Variável que mantém o valor total do desconto a ser aplicado */
  const [totalDiscountValue, setTotalDiscountValue] = useState(0);

  /**Flag que controla se as operações de submissão do accordion estão ocorrendo */
  const [isLoading, setIsLoading] = useState(false);

  const [paymentInfoDefined, setPaymentInfoDefined] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get("igti_checkout_payment_info");
    if (param) {
      setPaymentInfoDefined(true);
    }
  }, []);

  /**Função que realiza a máscara do input de número do cartão */
  const handleMaskCardNumber = useCallback((value: string) => {
    value = value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2");

    setNumber(value);
  }, []);

  /**Função que valida o número do cartão, determinando sua bandeira */
  const handleValidateCardNumber = useCallback(() => {
    /**Objeto contendo os regex de validação de cada bandeira do cartão */
    const banners = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})/,
      Mastercard:
        /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}/,
      Amex: /^3[47][0-9]{13}/,
      ELO: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    };

    for (const banner in banners)
      if (
        number
          .replaceAll(" ", "")
          .match(banners[banner as "Visa" | "Mastercard" | "Amex" | "ELO"])
      ) {
        setBanner(banner);
        return { valid: true, banner };
      }

    setBanner("");
    return { valid: false, banner: "" };
  }, [number]);

  /**Função que valida a data de vencimento do cartão */
  const handleValidateValidityDate = useCallback(() => {
    const [month, year] = validity.split("/");

    return (
      new Date(`${year}-${month}`).getTime() > new Date(Date.now()).getTime()
    );
  }, [validity]);

  /**Função que calcula o número e valor das opções de parcelamento disponíveis para o usuário */
  const handleCalculateInstallments = useCallback(() => {
    let buyMaxInstallments = 0;
    let buyMinInstallmentValue = 0;

    if (Object.keys(paymentRules).length > 0) {
      /**Função que reseta o valor das parcelas, adicionado-se valor de taxa de matrícula quando aplicaável, toda vez que uma variável de forma de pagamento ou parcela for alterada, para evitar o conflito entre operações */
      const resetInstallments = () => {
        const totalInstallments = acquiredProducts
          .map(({ crypted_product_type_id, valor_descontado }) => {
            const { payment_discount, min_installment_value } =
              acquiredProducts[0].payment_rules ??
              productTypes.filter(
                ({ crypted_id }) => crypted_id === crypted_product_type_id
              )[0];

            const { installments } = payment_discount.find(
              ({ payment_type }) =>
                payment_type === currentSpecificSelectedPaymentType
            ) ?? { installments: 1 };

            return {
              valor_descontado,
              installments,
              min_installment_value,
            };
          })
          .reduce(
            (prev, { installments, min_installment_value }) => {
              buyMaxInstallments = installments;
              buyMinInstallmentValue = min_installment_value;

              /**Pega o maior número de parcelas aceitas dentre os produtos adquiridos */
              prev.number_of_installments = Math.max(
                prev.number_of_installments,
                min_installment_value
                  ? paymentInfo.value <= min_installment_value
                    ? 1
                    : installments
                  : // : Math.min(
                    //     Math.floor(paymentInfo.value / min_installment_value),
                    //     installments
                    //   )
                    installments
              );

              prev.valor_total += paymentInfo.value;

              return prev;
            },
            { valor_total: 0, number_of_installments: 0 }
          );

        setInstallments([
          ...Array(totalInstallments.number_of_installments).fill(
            totalInstallments.valor_total
          ),
        ]);
      };
      resetInstallments();

      /**Verifica a aplicação de desconto por compra a vista */
      if (
        paymentRules.cash_payment.payment_types.includes(
          currentSpecificSelectedPaymentType.toLowerCase()
        )
      ) {
        /**Variável contendo o valor total dos produtos cujo desconto por pagamento a vista deve ser aplicado */
        const totalDiscountAppliableValue = acquiredProducts.reduce(
          (prev, { crypted_product_type_id, valor_descontado }) => {
            if (
              paymentRules.cash_payment.crypted_product_types.includes(
                crypted_product_type_id
              )
            )
              prev += valor_descontado;

            return prev;
          },
          0
        );

        setPaymentInfo((prev) => {
          return {
            ...prev,
            discounts: [
              ...(prev.discounts
                ? [
                    ...prev.discounts.filter(
                      ({ name }) => name !== "Compra à vista"
                    ),
                  ]
                : []),
              {
                name: "Compra à vista",
                value:
                  totalDiscountAppliableValue *
                  (paymentRules.cash_payment.value / 100),
              },
            ],
          };
        });

        setInstallments((prev) => {
          return prev.map((value, index) =>
            index === 0
              ? value -
                totalDiscountAppliableValue *
                  (paymentRules.cash_payment.value / 100)
              : value
          );
        });
      }

      /**Verifica a incidência de juros sob o valor pago */
      const selectedPayment = currentSpecificSelectedPaymentType.toLowerCase();

      if (selectedPayment in paymentRules.fee) {
        let currentProductFee = 0;
        let currentFeeValue = 0;

        if (acquiredProducts.length > 0) {
          // Itera sobre a lista de fee para o tipo de pagamento selecionado
          const feeForProduct = paymentRules.fee[selectedPayment as keyof IFee].find(
            (feeItem) => feeItem.crypted_product_type === acquiredProducts[0].crypted_product_type_id
          );
      
          if (feeForProduct) {
            // Define o valor do juros (fee) do produto encontrado
            currentFeeValue = feeForProduct.value / 100;
      
            // Calcula o valor do juros com base no produto adquirido
            currentProductFee = acquiredProducts[0]
              ? (((acquiredProducts[0].valor - totalDiscountValue) *
                  currentFeeValue) /
                  (1 -
                    (1 + currentFeeValue) ** (currentSelectedInstallment * -1))) *
                  currentSelectedInstallment -
                (acquiredProducts[0].valor - totalDiscountValue)
              : 0;
          }
        }

        setPaymentInfo((prev) => {
          return {
            ...prev,
            fee: currentProductFee,
            discounts: [
              ...(prev.discounts
                ? [
                    ...prev.discounts.filter(
                      ({ name }) => name !== "Cupom de desconto"
                    ),
                  ]
                : []),
              {
                name: "Cupom de desconto",
                value:
                  (prev.couponValue || 0) > 0
                    ? prev.generalist_voucher
                      ? (prev.couponValue || 0) - currentProductFee
                      : prev.couponValue || 0
                    : 0,
              },
            ],
          };
        });

        setInstallments((prev) => {
          return prev.map((_, index) => {
            if (currentFeeValue === 0) {
              return acquiredProducts[0].valor - totalDiscountValue;
            } else {
              return (
                acquiredProducts[0].valor -
                totalDiscountValue +
                (((acquiredProducts[0].valor - totalDiscountValue) *
                  currentFeeValue) /
                  (1 - (1 + currentFeeValue) ** ((index + 1) * -1))) *
                  (index + 1) -
                (acquiredProducts[0].valor - totalDiscountValue)
              );
            }
          });
        });
      } else {
        setPaymentInfo((prev) => {
          return {
            ...prev,
            fee: 0,
            discounts: [
              ...(prev.discounts
                ? [
                    ...prev.discounts.filter(
                      ({ name }) => name !== "Cupom de desconto"
                    ),
                  ]
                : []),
              {
                name: "Cupom de desconto",
                value: prev.couponValue || 0,
              },
            ],
          };
        });
      }

      setInstallments((prev) => {
        for (
          let installment = 1;
          installment <= buyMaxInstallments;
          installment++
        ) {
          if (prev[installment - 1] / installment <= buyMinInstallmentValue) {
            return prev.slice(0, installment - 1);
          }
        }
        return prev;
      });

      /**Variável contendo o valor total de taxas de matrículas a serem aplicadas */
      const totalEnrollmentValue = productTypes
        .filter(({ crypted_id }) =>
          acquiredProducts
            .map(({ crypted_product_type_id }) => crypted_product_type_id)
            .includes(crypted_id)
        )
        .reduce((prev, { enrollment_value }) => {
          prev += enrollment_value;
          return prev;
        }, 0);

      setPaymentInfo((prev) => {
        return {
          ...prev,
          additionals: [
            ...(prev.additionals
              ? [
                  ...prev.additionals.filter(
                    ({ name }) => name !== "Taxa(s) de matrícula"
                  ),
                ]
              : []),
            {
              name: "Taxa(s) de matrícula",
              value: totalEnrollmentValue,
            },
          ],
        };
      });
    }
  }, [
    acquiredProducts,
    productTypes,
    paymentRules,
    paymentInfo.value,
    setPaymentInfo,
    currentSpecificSelectedPaymentType,
    currentSelectedInstallment,
    totalDiscountValue,
  ]);

  /**Função responsável por validar todos os campos e submeter o accordion */
  const handleSubmitAccordion = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (document.querySelectorAll(".paymentInfoInput.invalid").length === 0) {
        try {
          setIsLoading(true);

          const lead = sessionStorage.getItem("@checkout_lead@");

          if (!lead) {
            addToast({
              type: "info",
              title: "Informações incompletas",
              description:
                "Insira e confirme suas informações pessoais para efetuar o pagamento",
            });

            setIsLoading(false);
            return;
          }

          if (
            currentSpecificSelectedPaymentType.toLowerCase() ===
            "boleto_parcelado"
          ) {
            const {
              data: { response: principia_url, success },
            } = await baseAPI.post(
              `/leads/${lead}/payments/boleto_parcelado`,
              {
                value: paymentInfo.value,
                installments: paymentInfo.installments,
                contract: [
                  ...Array(paymentInfo.installments)
                    .fill(paymentInfo.installment_value)
                    .map((value, index) => {
                      return {
                        installment: index + 1,
                        installment_value:
                          paymentInfo.value / paymentInfo.installments,
                      };
                    }),
                ],
                ...(paymentInfo.transaction_id && {
                  ref_transaction_id: paymentInfo.transaction_id,
                }),
              },
              { headers: baseHeaders() }
            );

            if (success) {
              addToast({
                type: "success",
                title: "Boleto gerado com sucesso",
                description: `O boleto foi gerado com sucesso. Clique em "visualizar boleto" para efetuar o pagamento.`,
              });

              setTicket(principia_url);
            } else {
              addToast({
                type: "error",
                title: "Erro ao efetuar pagamento",
                description:
                  "Ocorreu um erro ao realizar o pagamento. Entre em contato com o nosso suporte",
              });
            }
          } else if (
            currentSpecificSelectedPaymentType.toLowerCase() === "boleto"
          ) {
            const {
              data: { response, success },
            } = await baseAPI.post(
              `/leads/${lead}/payments/`,
              {
                value: paymentInfo.value,
                installments: paymentInfo.installments,
                installment_value: paymentInfo.installment_value,
                payment_type: paymentInfo.payment_type,
                ...(paymentInfo.transaction_id && {
                  ref_transaction_id: paymentInfo.transaction_id,
                }),
              },
              { headers: baseHeaders() }
            );

            if (success) {
              addToast({
                type: "success",
                title: "Boleto gerado com sucesso",
                description: `O boleto foi gerado com sucesso. Clique em "visualizar boleto" para efetuar o pagamento.`,
              });

              setTicket(response);
            } else {
              addToast({
                type: "error",
                title: "Erro ao efetuar pagamento",
                description:
                  "Ocorreu um erro ao realizar o pagamento. Entre em contato com o nosso suporte",
              });
            }
          } else {
            if (!handleValidateValidityDate()) {
              addToast({
                type: "info",
                title: "Data inválida",
                description: "A data informada é inválida",
              });

              setIsLoading(false);
              return;
            }

            const { banner } = handleValidateCardNumber();

            let payloadData = {
              card_number: number.replaceAll(" ", ""),
              card_holder: name,
              expiration_date: validity,
              cv_code: cvv,
              card_brand: banner,

              value: paymentInfo.value,
              installments: paymentInfo.installments,
              installment_value:
                currentSpecificSelectedPaymentType.toLowerCase() ===
                "recorrente"
                  ? (paymentInfo.installment_value * paymentInfo.installments +
                      (paymentInfo.fee || 0)) /
                    paymentInfo.installments
                  : paymentInfo.installment_value,
              payment_type: paymentInfo.payment_type,
              ...(paymentInfo.transaction_id && {
                ref_transaction_id: paymentInfo.transaction_id,
              }),
            };

            const {
              data: {
                success,
                response,
                additional_data: { transaction_uuid },
              },
            } = await baseAPI.post(`/leads/${lead}/payments/`, payloadData, {
              headers: baseHeaders(),
            });

            if (success) {
              if (response === true) {
                addToast({
                  type: "success",
                  title: "Pagamento efetuado com sucesso",
                  description: "O pagamento foi efetuado com sucesso",
                });

                /**Valida o accordion */
                setAccordions((prev) =>
                  prev.map((acc) =>
                    acc.id === id
                      ? { ...acc, completed: true, disabled: true }
                      : { ...acc }
                  )
                );

                if (process.env.REACT_APP_ENV === "PROD") {
                  setTimeout(() => {
                    window.location.replace(
                      `https://checkout.xpeducacao.com.br/confirmacao-compra/?transaction_id=${transaction_uuid}&value=${
                        paymentInfo.payment_type.toLowerCase() === "recorrente"
                          ? paymentInfo.value +
                            paymentInfo.additionals.filter(
                              ({ name }) => name === "Taxa(s) de matrícula"
                            )[0].value
                          : paymentInfo.value
                      }&tax=0.00&shipping=0.00&currency=BRL${
                        paymentInfo.coupon
                          ? `&coupon=${paymentInfo.coupon}`
                          : ""
                      }&payment_type=${
                        paymentInfo.payment_type
                      }&item_name=${acquiredProducts.reduce(
                        (prev, { nome }, index) => {
                          index === 0 ? (prev = nome) : (prev += `-${nome}`);

                          return prev;
                        },
                        ""
                      )}&item_id=${acquiredProducts.reduce(
                        (prev, { crypted_id }, index) => {
                          index === 0
                            ? (prev = crypted_id)
                            : (prev += `-${crypted_id}`);

                          return prev;
                        },
                        ""
                      )}&price=${
                        paymentInfo.payment_type.toLowerCase() === "recorrente"
                          ? paymentInfo.value +
                            paymentInfo.additionals.filter(
                              ({ name }) => name === "Taxa(s) de matrícula"
                            )[0].value
                          : paymentInfo.value
                      }&item_brand=XP Educação&item_category=${acquiredProducts.map(
                        ({ crypted_product_type_id }) =>
                          productTypes
                            .filter(
                              ({ crypted_id }) =>
                                crypted_product_type_id === crypted_id
                            )
                            .reduce((prev, { tipo_produto }, index) => {
                              index === 0
                                ? (prev = tipo_produto)
                                : (prev += `-${tipo_produto}`);

                              return prev;
                            }, "")
                      )}&quantity=1`
                    );
                  }, 2000);
                } else {
                  console.log("redirect");
                }
              } else {
                addToast({
                  type: "success",
                  title: "Pagamento gerado com sucesso",
                  description:
                    "O link de pagamento foi gerado com sucesso. Clique em 'Visualizar link' para efetuar o pagamento",
                });

                setTicket(response);
              }
            } else {
              addToast({
                type: "error",
                title: "Não foi possível concluir o pagamento.",
                description:
                  "Por favor, revise as informações do cartão ou entre em contato com seu banco.",
              });
            }
          }
        } catch {
          addToast({
            type: "error",
            title: "Não foi possível concluir o pagamento.",
            description:
              "Por favor, revise as informações do cartão ou entre em contato com seu banco.",
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        setAccordions((prev) =>
          prev.map((acc) =>
            acc.id === id ? { ...acc, completed: false } : { ...acc }
          )
        );

        addToast({
          type: "info",
          title: "Informações faltantes",
          description: "Preencha os campos corretamente",
        });
      }
    },
    [
      id,
      currentSpecificSelectedPaymentType,
      setAccordions,
      paymentInfo,
      handleValidateCardNumber,
      handleValidateValidityDate,
      addToast,
      cvv,
      name,
      number,
      validity,
      acquiredProducts,
      productTypes,
    ]
  );

  /**Efeito que recupera as regras de pagamento a serem aplicadas */
  useEffect(() => {
    const getPaymentRules = async () => {
      const {
        data: { response },
      } = await baseAPI.get(`/payment_rules/`, { headers: baseHeaders() });

      setPaymentRules(response);
    };

    getPaymentRules();
  }, []);

  /**Efeito que padroniza a opção de parcelamento inicial baseado no produto escolhido */
  useEffect(() => {
    const [foundSignature] = acquiredProducts.filter(
      ({ crypted_product_type_id }) =>
        crypted_product_type_id === ProductTypesIds.ASSINATURA ||
        crypted_product_type_id === ProductTypesIds.BOOTCAMP
    );

    if (foundSignature && currentSpecificSelectedPaymentType !== "boleto") {
      if (foundSignature.crypted_id === ProductIds.BOOTCAMP_PASS_ANUAL)
        setCurrentSelectedInstallment(12);
      else if (foundSignature.crypted_id === ProductIds.BOOTCAMP_PASS_SEMESTRAL)
        setCurrentSelectedInstallment(6);
      else if (foundSignature.crypted_id === ProductIds.BOOTCAMP_PASS_MENSAL)
        setCurrentSelectedInstallment(1);
      else if (
        foundSignature.crypted_id === ProductIds.BOOTCAMP_PASS_TRIMESTRAL
      )
        setCurrentSelectedInstallment(3);
    }
  }, [
    currentGenericSelectedPaymentType,
    currentSpecificSelectedPaymentType,
    acquiredProducts,
  ]);

  /**Efeito que calcula o número de opções de parcelamento disponíveis para o usuário */
  useEffect(() => {
    handleCalculateInstallments();
  }, [handleCalculateInstallments]);

  /**Efeito que atualiza as informações de pagamento sempre que mudanças ocorrerem */
  useEffect(() => {
    setPaymentInfo((prev) => {
      /**Recupera o valor total dos produtos sendo adquiridos (sem incluir taxa de matrícula) */
      const totalValue = acquiredProducts.reduce(
        (prev, { valor }) => (prev += valor),
        0
      );

      /**Faz a verificação das condições de aplicação do desconto de compra antecipada (forma de pagamento e número de parcelas) */
      const { payment_discount } =
        Object.keys(productTypes).length > 0 && acquiredProducts.length > 0
          ? acquiredProducts[0].payment_rules ??
            productTypes.find(
              ({ crypted_id }) =>
                crypted_id === acquiredProducts[0].crypted_product_type_id
            )!
          : { payment_discount: [] };

      const { discount, discount_exception } = payment_discount.find(
        ({ payment_type }) =>
          payment_type === currentSpecificSelectedPaymentType
      ) ?? {
        discount: 0,
        discount_exception: [{ discount: 0, installment: 1 }],
      };

      /**Valor total do desconto de compra antecipada (dentre este desconto, e o desconto do cupom, apenas 1 pode estar ativo) */
      // const earlyBuyDiscountValue =
      //    discount_exception && currentSelectedInstallment === discount_exception.installment
      //       ? totalValue * (discount_exception.discount / 100)
      //       : totalValue * (discount / 100);

      const matchingDiscount = discount_exception
        ? discount_exception.find(
            (item) => item.installment === currentSelectedInstallment
          )
        : null;

      const earlyBuyDiscountValue = matchingDiscount
        ? totalValue * (matchingDiscount.discount / 100)
        : totalValue * (discount / 100);

      /**Valor total de taxas de matrícula envolvidas (não participam dos descontos) */
      const { value: enrollmentValue } = prev.additionals.find(
        ({ name }) => name === "Taxa(s) de matrícula"
      ) ?? { value: 0 };

      const currentInstallmentValue =
        currentSpecificSelectedPaymentType === "recorrente"
          ? (totalValue -
              ((paymentInfo.generalist_voucher ? 0 : earlyBuyDiscountValue) ??
                0) -
              (paymentInfo.couponValue ?? 0)) /
            currentSelectedInstallment
          : (totalValue -
              ((paymentInfo.generalist_voucher ? 0 : earlyBuyDiscountValue) ??
                0) -
              (paymentInfo.couponValue ?? 0) +
              enrollmentValue) /
            currentSelectedInstallment;

      const currentValue =
        totalValue -
        ((paymentInfo.generalist_voucher ? 0 : earlyBuyDiscountValue) ?? 0) -
        (paymentInfo.couponValue ?? 0) +
        enrollmentValue +
        (paymentInfo.fee || 0);

      return {
        ...prev,
        discounts: [
          ...(prev.discounts
            ? [
                ...prev.discounts.filter(
                  ({ name }) => name !== "Desconto por forma de pagamento"
                ),
              ]
            : []),
          {
            name: "Desconto por forma de pagamento",
            value: paymentInfo.generalist_voucher
              ? 0
              : earlyBuyDiscountValue - (paymentInfo.fee || 0),
          },
        ],
        additionals:
          Object.keys(paymentRules).length > 0 &&
          !(currentSpecificSelectedPaymentType in paymentRules.fee)
            ? prev.additionals.filter(({ name }) => name !== "Juros")
            : prev.additionals,
        payment_type: currentSpecificSelectedPaymentType || "-",
        value: currentValue,
        installments: currentSelectedInstallment || 1,
        installment_value: currentInstallmentValue,
      };
    });
  }, [
    acquiredProducts,
    productTypes,
    paymentRules,
    installments,
    currentSelectedInstallment,
    currentSpecificSelectedPaymentType,
    paymentInfo.couponValue,
    paymentInfo.generalist_voucher,
    setPaymentInfo,
    paymentInfo.fee,
  ]);

  /**Efeito que atualiza a variável que mantém o passo atual do usuário, quando o accordion for validado */
  useEffect(() => {
    setActiveStep(accordions!.findIndex(({ disabled }) => !disabled));
  }, [accordions, setActiveStep]);

  /**Efeito que atualiza o valor total da compra ao adquirir novos produtos */
  useEffect(() => {
    setTotalValue(
      acquiredProducts.reduce((prev, { valor }) => (prev += valor), 0)
    );
  }, [acquiredProducts]);

  /**Efeito que atualiza o valor total da(s) taxa(s) de matrícula ao receber as informações do pagamento */
  useEffect(() => {
    const { value: newEnrollmentValue } = paymentInfo.additionals.find(
      ({ name }) => name === "Taxa(s) de matrícula"
    ) ?? { value: 0 };

    setEnrollmentValue(newEnrollmentValue);
  }, [paymentInfo.additionals]);

  /**Efeito que atualiza o valor de desconto de compra antecipada baseado na forma de pagamento de número de parcelas */
  useEffect(() => {
    /**Recupera o valor total dos produtos sendo adquiridos (sem incluir taxa de matrícula) */
    const totalValue = acquiredProducts.reduce(
      (prev, { valor }) => (prev += valor),
      0
    );

    /**Faz a verificação das condições de aplicação do desconto de compra antecipada (forma de pagamento e número de parcelas) */
    const { payment_discount } =
      Object.keys(productTypes).length > 0 && acquiredProducts.length > 0
        ? acquiredProducts[0].payment_rules ??
          productTypes.find(
            ({ crypted_id }) =>
              crypted_id === acquiredProducts[0].crypted_product_type_id
          )!
        : { payment_discount: [] };

    const { discount, discount_exception } = payment_discount.find(
      ({ payment_type }) => payment_type === paymentInfo.payment_type
    ) ?? {
      discount: 0,
      discount_exception: [
        {
          discount: 0,
          installment: 1,
        },
      ],
    };

    //  const earlyBuyDiscountValue =
    //    discount_exception &&
    //    paymentInfo.installments === discount_exception.installment
    //      ? totalValue * (discount_exception.discount / 100)
    //      : totalValue * (discount / 100);

    const matchingDiscount = discount_exception
      ? discount_exception.find(
          (item) => item.installment === paymentInfo.installments
        )
      : null;

    const earlyBuyDiscountValue = matchingDiscount
      ? totalValue * (matchingDiscount.discount / 100)
      : totalValue * (discount / 100);

    if (paymentInfo.generalist_voucher) {
      setTotalDiscountValue(paymentInfo.couponValue ?? 0);
    } else {
      setTotalDiscountValue(
        earlyBuyDiscountValue + (paymentInfo.couponValue ?? 0)
      );
    }
  }, [acquiredProducts, productTypes, paymentInfo]);

  /**Efeito que ajusta a parcela atualmente selecionada caso a mesma seja maior do que o número máximo de parcelas */
  useEffect(() => {
    installments[0] &&
      installments.length > 1 &&
      setCurrentSelectedInstallment((prev) => {
        return prev > installments.length ? 1 : prev;
      });
  }, [installments]);
  return (
    <>
      <CustomAccordion
        id={id}
        title="Forma de Pagamento"
        disabled={disabled}
        expanded={expanded}
        setAccordions={setAccordions}
      >
        <div>
          <StyledRadioGroup
            value={currentGenericSelectedPaymentType || "-"}
            onChange={({ target: { value } }) => {
              setCurrentGenericSelectedPaymentType(value);
              setCurrentSpecificSelectedPaymentType("-");
              setPaymentInfo((prev) => {
                return {
                  ...prev,
                  discounts: prev.discounts.filter(
                    ({ name }) => name !== "Cupom de desconto"
                  ),
                  coupon: "",
                  couponValue: 0,
                  generalist_voucher: false,
                };
              });
            }}
          >
            {Object.keys(
              Array.from(
                new Set(
                  acquiredProducts.reduce(
                    (prev, { crypted_product_type_id, payment_rules }) => {
                      const { payment_types_description } =
                        payment_rules ??
                        productTypes.filter(
                          ({ crypted_id }) =>
                            crypted_id === crypted_product_type_id
                        )[0];

                      prev = [...prev, ...payment_types_description!];

                      return prev;
                    },
                    [] as { nome: string; nome_exibicao: string }[]
                  )
                )
              ).reduce((obj, { nome, nome_exibicao }) => {
                const [genericTypeName] = nome_exibicao.split(" ");

                obj[genericTypeName] = obj[genericTypeName]
                  ? [...obj[genericTypeName], { nome, nome_exibicao }]
                  : [{ nome, nome_exibicao }];

                return obj;
              }, {} as { [key: string]: { nome: string; nome_exibicao: string }[] })
            ).map((genericType, index) => (
              <StyledGenericFormControlLabel
                key={index}
                label={<Typography>{genericType}</Typography>}
                value={genericType}
                disabled={paymentInfoDefined}
                checked={genericType === currentGenericSelectedPaymentType}
                control={
                  <Radio
                    icon={
                      genericType.toLowerCase().includes("boleto") ? (
                        <img src="ticket_barcode.svg" alt="Ícone Boleto" />
                      ) : (
                        <img src="credit_card.svg" alt="Ícone Cartão" />
                      )
                    }
                    checkedIcon={
                      <div>
                        {genericType.toLowerCase().includes("boleto") ? (
                          <img src="ticket_barcode.svg" alt="Ícone Boleto" />
                        ) : (
                          <img src="credit_card.svg" alt="Ícone Cartão" />
                        )}

                        <CheckIcon fontSize="small" className="checkedIcon" />
                      </div>
                    }
                  />
                }
              />
            ))}
          </StyledRadioGroup>
        </div>

        <div>
          <div>
            {Object.entries(
              Array.from(
                new Set(
                  acquiredProducts.reduce(
                    (prev, { crypted_product_type_id, payment_rules }) => {
                      const { payment_types_description } =
                        payment_rules ??
                        productTypes.filter(
                          ({ crypted_id }) =>
                            crypted_id === crypted_product_type_id
                        )[0];

                      prev = [...prev, ...payment_types_description!];

                      return prev;
                    },
                    [] as { nome: string; nome_exibicao: string }[]
                  )
                )
              ).reduce((obj, { nome, nome_exibicao }) => {
                const [genericTypeName] = nome_exibicao.split(" ");

                obj[genericTypeName] = obj[genericTypeName]
                  ? [...obj[genericTypeName], { nome, nome_exibicao }]
                  : [{ nome, nome_exibicao }];

                return obj;
              }, {} as { [key: string]: { nome: string; nome_exibicao: string }[] })
            ).map(([genericType, specificTypes], index) =>
              genericType === currentGenericSelectedPaymentType ? (
                <StyledRadioGroup
                  value={currentSpecificSelectedPaymentType || "-"}
                  onChange={({ target: { value } }) => {
                    setCurrentSpecificSelectedPaymentType(value);
                    setPaymentInfo((prev) => {
                      return {
                        ...prev,
                        discounts: prev.discounts.filter(
                          ({ name }) => name !== "Cupom de desconto"
                        ),
                        coupon: "",
                        couponValue: 0,
                        generalist_voucher: false,
                      };
                    });
                    Object.keys(paymentRules).length > 0 &&
                      !paymentRules.installment_allowed.includes(
                        value.toLowerCase()
                      ) &&
                      setCurrentSelectedInstallment(1);
                  }}
                  key={index}
                >
                  {specificTypes.map(({ nome, nome_exibicao }, index) => (
                    <StyledSpecificFormControlLabel
                      key={index}
                      label={<Typography>{nome_exibicao ?? nome}</Typography>}
                      disabled={paymentInfoDefined}
                      value={nome}
                      control={<Radio />}
                    />
                  ))}
                </StyledRadioGroup>
              ) : (
                ""
              )
            )}
            {(currentGenericSelectedPaymentType.toLowerCase() === "boleto" ||
              currentGenericSelectedPaymentType.toLowerCase() ===
                "boleto_parcelado") && (
              <BoletoVality>
                *Importante: O primeiro boleto (à vista ou primeira parcela) de
                seu pagamento tem validade de 1 dia útil.
              </BoletoVality>
            )}
          </div>
        </div>

        <div>
          <div>
            {ticket === "" && (
              <form onSubmit={(e) => handleSubmitAccordion(e)}>
                <InputList>
                  {!(
                    currentGenericSelectedPaymentType.toLowerCase() === "boleto"
                  ) && (
                    <div>
                      <InputItem sizes={banner !== "" ? [1, 0.1] : [1]}>
                        <TextField
                          id="cardnumber"
                          className={`${
                            number.length < 16 ? "invalid" : ""
                          } paymentInfoInput`}
                          label="Número do cartão"
                          variant="outlined"
                          inputProps={{ minLength: 16, maxLength: 19 }}
                          value={number || ""}
                          required
                          autoComplete="off"
                          onChange={({ target: { value } }) =>
                            handleMaskCardNumber(value)
                          }
                          onBlur={() => handleValidateCardNumber()}
                        />

                        {banner !== "" && (
                          <div
                            style={{
                              maxHeight: "3.5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              borderRadius: "4px",
                            }}
                          >
                            <img
                              width={50}
                              height={50}
                              src={`${banner}.svg`}
                              alt="Bandeira do Cartão"
                            />
                          </div>
                        )}
                      </InputItem>

                      <InputItem sizes={[1]}>
                        <TextField
                          id="cardname"
                          className={`paymentInfoInput`}
                          label="Nome do titular"
                          variant="outlined"
                          inputProps={{ maxLength: 30 }}
                          value={name || ""}
                          required
                          onChange={({ target: { value } }) => setName(value)}
                        />
                      </InputItem>

                      <InputItem sizes={[1, 0.5]}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt"
                          localeText={
                            ptBR.components.MuiLocalizationProvider.defaultProps
                              .localeText
                          }
                        >
                          <DatePicker
                            className={`${
                              validity.length < 1 ? "invalid" : ""
                            } paymentInfoInput`}
                            label="Validade"
                            views={["month", "year"]}
                            format="MM/YYYY"
                            //@ts-ignore
                            onChange={({ $M, $y }) =>
                              setValidity(
                                `${($M + 1).toString().padStart(2, "0")}/${$y}`
                              )
                            }
                          />
                        </LocalizationProvider>

                        <TextField
                          id="cvv"
                          className={`${
                            cvv.length < 3 ? "invalid" : ""
                          } paymentInfoInput`}
                          label="CVV"
                          variant="outlined"
                          inputProps={{ minLength: 3, maxLength: 4 }}
                          value={cvv || ""}
                          required
                          onChange={({ target: { value } }) => setCvv(value)}
                        />
                      </InputItem>
                    </div>
                  )}

                  <InputItem sizes={[1]}>
                    <TextField
                      id="installments"
                      className="paymentInfoInput"
                      label="Em quantas parcelas deseja pagar?"
                      select
                      disabled={
                        paymentInfoDefined ||
                        currentSpecificSelectedPaymentType === "-" ||
                        currentSpecificSelectedPaymentType === "boleto"
                      }
                      value={
                        installments.length > 0 &&
                        currentSpecificSelectedPaymentType !== "-"
                          ? currentSelectedInstallment
                          : ""
                      }
                      onChange={({ target: { value } }) =>
                        setCurrentSelectedInstallment(Number(value))
                      }
                    >
                      {installments.map((_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {currentSpecificSelectedPaymentType
                            .toLowerCase()
                            .includes("recorrente") ? (
                            index === 0 ? (
                              <div>
                                {index + 1}x de{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  installments[index] + enrollmentValue
                                )}
                              </div>
                            ) : acquiredProducts[0].crypted_product_type_id ===
                              "b3b3970b4d627af7ed485f05a99e096a" ? (
                              <div>
                                {index + 1}x de{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(installments[index] / (index + 1))}
                              </div>
                            ) : (
                              <div>
                                1x de{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  installments[index] / (index + 1) +
                                    enrollmentValue
                                )}{" "}
                                - {index}x de{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  installments[index] / (index + 1)
                                )}{" "}
                                {!currentSpecificSelectedPaymentType
                                  .toLowerCase()
                                  .includes("recorrente") && "s/ juros"}
                              </div>
                            )
                          ) : (
                            <div>
                              {index + 1}x de{" "}
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(
                                (() => {
                                  /**Faz a verificação das condições de aplicação do desconto de compra antecipada (forma de pagamento e número de parcelas) */
                                  const { payment_discount } =
                                    Object.keys(productTypes).length > 0 &&
                                    acquiredProducts.length > 0
                                      ? acquiredProducts[0].payment_rules ??
                                        productTypes.find(
                                          ({ crypted_id }) =>
                                            crypted_id ===
                                            acquiredProducts[0]
                                              .crypted_product_type_id
                                        )!
                                      : { payment_discount: [] };

                                  const { discount, discount_exception } =
                                    payment_discount.find(
                                      ({ payment_type }) =>
                                        payment_type ===
                                        paymentInfo.payment_type
                                    ) ?? {
                                      discount: 0,
                                      discount_exception: [
                                        {
                                          discount: 0,
                                          installment: 1,
                                        },
                                      ],
                                    };

                                  // const earlyBuyDiscountValue =
                                  //   discount_exception &&
                                  //   index + 1 === discount_exception.installment
                                  //     ? totalValue *
                                  //       (discount_exception.discount / 100)
                                  //     : totalValue * (discount / 100);

                                  const matchingDiscount = discount_exception
                                    ? discount_exception.find(
                                        (item) => item.installment === index + 1
                                      )
                                    : null;

                                  if (
                                    currentSpecificSelectedPaymentType ===
                                    "boleto_parcelado"
                                  ) {
                                    return (
                                      (installments[index] + enrollmentValue) /
                                      (index + 1)
                                    );
                                  }

                                  const earlyBuyDiscountValue = matchingDiscount
                                    ? totalValue *
                                      (matchingDiscount.discount / 100)
                                    : totalValue * (discount / 100);

                                  if (paymentInfo.generalist_voucher) {
                                    return (
                                      (totalValue -
                                        (paymentInfo.couponValue ?? 0) +
                                        enrollmentValue) /
                                      (index + 1)
                                    );
                                  } else {
                                    return (
                                      (totalValue -
                                        (earlyBuyDiscountValue +
                                          (paymentInfo.couponValue ?? 0)) +
                                        enrollmentValue) /
                                      (index + 1)
                                    );
                                  }
                                })()
                              )}{" "}
                              {index !== 0 &&
                                !(
                                  currentGenericSelectedPaymentType.toLowerCase() ===
                                  "boleto"
                                ) &&
                                "s/ juros"}
                            </div>
                          )}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputItem>
                </InputList>

                {ticket === "" && (
                  <Button
                    id="paymentInfoSubmitButton"
                    type="submit"
                    variant="contained"
                    color="success"
                    disabled={
                      currentSpecificSelectedPaymentType === "-" ||
                      (currentGenericSelectedPaymentType.toLowerCase() !==
                        "boleto" &&
                        (number.length < 16 ||
                          name.length < 1 ||
                          validity.length < 1 ||
                          cvv.length < 3))
                    }
                    sx={{ width: "100%", marginTop: "1rem" }}
                  >
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <span>Confirmar Pagamento</span>
                    )}
                  </Button>
                )}
              </form>
            )}

            {ticket !== "" && (
              // <Button
              //   id="paymentInfoSubmitButton"
              //   type="submit"
              //   variant="contained"
              //   color="success"
              //   sx={{ width: "100%", marginTop: "1rem" }}
              // >
              //   <a
              //     style={{ textDecoration: "none" }}
              //     href={ticket}
              //     target="_blank"
              //     rel="noreferrer"
              //   >
              //     <span>
              //       {paymentInfo.payment_type === "parcelado"
              //         ? "Visualizar link"
              //         : "Visualizar boleto"}
              //     </span>
              //   </a>
              // </Button>
              <a
                style={{ textDecoration: "none" }}
                href={ticket}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  id="paymentInfoSubmitButton"
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ width: "100%", marginTop: "1rem" }}
                >
                  {paymentInfo.payment_type === "parcelado"
                    ? "Visualizar link"
                    : "Visualizar boleto"}
                </Button>
              </a>
            )}
          </div>
        </div>
      </CustomAccordion>
    </>
  );
};

export default PaymentInfoAccordion;
